<template>
    <v-container fluid>
        <v-card class="px-3">
            <v-card-title class="text-h4">
                Show Management
                <v-spacer></v-spacer>
                <new-show></new-show>
            </v-card-title>

        </v-card>
        <v-card class="px-3 mt-3">
            <v-tabs v-model="tab">
                <v-tab>Upcoming</v-tab>
                <v-tab>Requested</v-tab>
                <v-tab>Completed</v-tab>
            </v-tabs>
            <v-tabs-items v-model="tab">
                <v-tab-item>
                    <upcoming-show></upcoming-show>
                </v-tab-item>
                <v-tab-item>
                    <requested-shows></requested-shows>
                </v-tab-item>
                <v-tab-item>
                    <completed-shows></completed-shows>
                </v-tab-item>
            </v-tabs-items>
        </v-card>
    </v-container>
</template>
<script>
import newShow from "../../../components/dialogs/shows/newShow.vue"
import RequestedShows from "../../../components/dataTables/shows/requestedShows.vue";
import UpcomingShow from '../../../components/dataTables/shows/upcomingShow.vue';
import completedShows from "../../../components/dataTables/shows/completedShows.vue";
export default
    {
        name: 'showManagement',
        data() {
            return {
                tab: null
            }
        },
        components: { newShow, RequestedShows, UpcomingShow, completedShows },
    }
</script>